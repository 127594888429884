<ion-row class="ph-50">
  <ion-col>
    <p class="sub-form-title">{{ props.label }}</p>
    <div>
      <ng-container #fieldComponent></ng-container>
    </div>
  </ion-col>
</ion-row>
<!-- <ion-row class="ph-50">
  <ion-col class="ion-text-left">
    <p class="faded small">
      Note: Enter either unit price or subtotal (excluding GST, transport and other charges)
    </p>
  </ion-col>
</ion-row> -->