<ion-row>
  <ion-col class="ion-text-center display-flex ion-justify-content-center ion-align-items-center">
    <div class="item-input-title"><ion-text color="medium">INPUT</ion-text></div>
    <ion-button (click)="addItem()" color="success" size="small" fill="clear" class="button-with-text">
      <ion-icon name="add-circle-outline" slot="icon-only"></ion-icon>
    </ion-button>
  <!-- </ion-col>
  <ion-col class="ion-text-right"> -->
  </ion-col>
</ion-row>
<ion-row *ngFor="let _field of field.fieldGroup; let i = index" >
  <ion-col>
    <div class="item-wrapper">
      <formly-field class="inline-field" [field]="_field"></formly-field>
      <ion-button (click)="removeField(i)" color="danger" fill="clear" *ngIf="i>0">
        <ion-icon name="trash"></ion-icon>
      </ion-button>
    </div>
  </ion-col>
</ion-row>